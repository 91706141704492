import React from 'react'
import { Input, Menu, Dropdown, Affix, Divider } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import notification from '../../../../../resources/images/notifications.svg';
import white_logo from '../../../../../resources/images/white_logo.svg';
import qm from '../../../../../resources/images/qm.svg';
import avatar from '../../../../../resources/images/avatar.png';
import invite from '../../../../../resources/images/invite.svg';
import qmplue from '../../../../../resources/images/qmplue.svg';
import notifications_gray from '../../../../../resources/images/notifications-gray.svg';






import { authLogout, setLocale } from '../../../Authintication/store/actions';

const { Search } = Input;

class Headers extends React.Component {
  render() {
    const { auth, header, users } = this.props.headerResources;
    const menu = (
      <Menu className='user-menu-wrapper'>
        <Menu.Item key="0">
          <Link to={'/dashboard/admin'}>
            <h6>Shady Mohamed</h6>
            <span>{header.SUPERADMIN}</span>
          </Link>
        </Menu.Item>

        <Menu.Item key="1">
          <Link to={'/settingProfile'}>{header.Editprofile}</Link>
        </Menu.Item>

        <Menu.Item key="3" onClick={() => {
          this.props.dispatch(authLogout())
          this.forceUpdate()
        }}>
          {auth.logout}
        </Menu.Item>
        <span className="menu_diveder"></span>

        <Menu.Item key="1" className="show_phone_wide">
          <Link to={'/#'}> Help</Link>
        </Menu.Item>

        <Menu.Item key="1"  className="show_phone_wide responsive_notification">
          <Link to={'/#'}>  
           <div className="notify-holder d-flex">
            Notifications
            <span className="notify-count">3</span>
          </div></Link>
        </Menu.Item>
      </Menu>
    )
    const notificationMenu = (
      <Menu className='notify-menu-wrapper'>
        <header>{header.NOTIFICATIONS}</header>
        <Menu.Item key="0">
          <Link to={'/dashboard/admin'}>
            <h6>Welcome back to Starwallet labs admin panel</h6>
            <span>5 Mins ago</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to={'/dashboard/admin'}>
            <h6>Welcome back to Starwallet labs admin panel</h6>
            <span>5 Mins ago</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to={'/dashboard/admin'}>
            <h6>Welcome back to Starwallet labs admin panel</h6>
            <span>5 Mins ago</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to={'/dashboard/admin'}>
            <span>{header.Seeall}</span>
          </Link>
        </Menu.Item>
      </Menu>
    )
    return (
      // <Affix offsetTop={1} >
      <div>




        <header className="teatcher_header">
          <div className="top_header">
            <img src={white_logo} />
            <div className="d-flex align-items-center">
              <h4 onClick={() => {
                this.props.dispatch(setLocale(this.props.currentLocale === 'english' ? 'ar' : 'english'))
              }} className='lang-btn'>{this.props.currentLocale === 'english' ? 'العربية' : 'English'}</h4>
              <Divider type="vertical" className="hide_phone_wide" />
              <Dropdown overlay={notificationMenu} trigger={['click']} className="hide_phone_wide">
                <div className="notify-holder d-flex" >
                  <img style={{ height: '25px' }} src={notification} alt="profile img" />
                  <span className="notify-count">{menu.props.children.length}</span>
                </div>
              </Dropdown>

              <img src={qm}  className="hide_phone_wide"/>

              <Dropdown overlay={menu} trigger={['click']}>
                <div className="profile-holder">
                  <img src={avatar} alt="profile img" />
                </div>
              </Dropdown>
            </div>
          </div>
        </header>


        {/* <div className="  bottom_header">
          <div className="d-flex align-items-center inner_header">
            <h4 className='lang-btn'>5/A Class</h4>
            <Divider type="vertical" />
            <a className="active">Classroom</a>
            <a>Portfolios</a>
            <a>Class Story</a>
            <a>Messages</a>
          </div>
          <div className="d-flex align-items-center">
            <p className="invite">
              <img src={invite} />
              Invite Parents
            </p>
            <Dropdown overlay={menu} trigger={['click']}>
              <div className="profile-holder">
                <p>Options</p>
              </div>
            </Dropdown>
          </div>
        </div> */}


      </div>
      // </Affix>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentLocale: state.common.currentLocale,
    headerResources: state.common.currentResource
  }
}

export default connect(mapStateToProps)(Headers)