export const ar = {
  auth: {
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    email: "بريد الكتروني",
    rememberMe: "تذكرني",
    signIn: "تسجيل الدخول",
    password_placeholder: "ادخل رقمك السري",
    mail_massage: "هذا البريد الالكتروني غير صالح",
    mail_req_massage: "الرجاء ادخال بريدك الالكتروني",
    password: "الرقم السري",
    forgetPassword: "نسيت كلمه السر",
    Forget_Password: "نسيت كلمه السر",
    sammary: "برجاء ادخال البريد الالكتروني لتتمكن من اعاده تعيين كلمه السر",
    passwordMassage: "الرجاء ادخال كلمه السر",
    backToSignin: "الرجوع الي تسجيل الدخول",
    sendMail: "ارسال بريد الكتروني",
    sorry403: "نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.",
    sorry505:
      "واجه الخادم خطأ داخليًا أو خطأ في التكوين وكان كذلك لا يمكن إكمال طلبك",
    resetSent: "تم إرسال كود إعادة التعيين بنجاح",
    plaeaseEnterMail:
      "الرجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.",
    confirmPassword: "تأكيد كلمة المرور الجديدة",
    restPass_placeholder: "اعد كتابه رقم المرور",
    confirm_massage: "الرجاء تاكيد كلمه السر",
    Reset: "إعادة تعيين",
    cancle: "الغاء",
    CheckInpoxp1: "يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في",
    CheckInpoxp2: "للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.",
    GoToMail: "اذهب الي البريد الالكتروني",
    Ok: "حسنا",
    goToHome: "اذهب الي الصفحه الرئيسيه",
    oops: "عفوا !!عنوان URLغير صحيح",
    ResendMail: "اعاده ارسال البريد الالكتروني",
    passwordmin: "يجب أن تكون كلمة المرور أقل من 8 أحرف",
    passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
    passwordexpresion:
      "تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
    profile: "الصفحه الشخصيه",
    personalInfo: "معلومات شخصيه",
    name: "الاسم",
    nameMassage: "الرجاء ادخال الاسم",
    namePlaceholder: "ادخل اسمك",
    Security: "الحمايه",
    Account: "حساب",
    Change: "تغيير",
    Deactivate: "تعطيل",
    DeactivateAccount: "تعطيل الحساب",
    SaveChanges: "حفظ التغييرات",
    ChangePassword: "تغيير الرقم السري",
    NoCancel: "الغاء",
    YesDeactivate: "حسنا , قم بتعطيل الحساب",
    PasswordsChanged: "تم تغيير الرقم السري",
    successfullyDeleted: " تم الحذف بنجاح",
    AccountDeactivated: "تم تعطيل الحساب",
    YourAccountDeactivated:
      "تم إلغاء تنشيط حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك",
    Delete: "مسح",
    AreYouSoure: "هل انت متاكد انك تريد مسح هذه المعلومات",
    YesSave: "نعم, احفظ",
    NoSave: "لا تحفظ",
    Cancel: "الغاء",
    EnterPassFor: "الرجاء ادخال الرقم السري الخاص ب",
    TimedOut:
      "لقد انتهت مهلتك بسبب الخمول. يرجى اختيار البقاء تم تسجيل الدخول أو تسجيل الخروج. خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا بعد 1 دقيقة.",
    SinginOrLogout: "تم تسجيل الدخول أو تسجيل الخروج",
    otherwise: "خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا ",
    minute: "بعد 1 دقيقة.",
    stay: "ابقي",
    inactivityMessage: "لقد تم تسجيل الخروج بسبب عدم النشاط",
    Singinyouraccount: "سجل الأن فى حسابك"
  },

  FAQ:{
    SearchforyourAnswer:' ابحث عن إجابتك',
    QuickAnswers:'أجوبة سريعة',
    PopularQuestions: 'أسئلة شائعه',
    writeyourquestionher:"اكتب سؤالك هنا ...",
    ExploreMoreAnswers:'استكشاف المزيد من الإجابات',
    ToImproveandkeepourservice:'للتحسين والحفاظ على خدمتنا ..',
    Incaseyou:'في حالة عدم العثور على الإجابة التي تبحث عنها ، يمكنك إرسال سؤالك إلينا وسأجيب عليك قريبًا.',
    selectacategory:'اختر تصنيف..',
    sendusyourquestionhere:'ارسل لنا سؤالك هنا ...',
    CanIDelete:'هل يمكنني حذف حسابي وقتما أرغب؟',
    successfullySubmitedQuestion: 'لقد قمت بإرسال سؤال بنجاح, سنقوم بالرد عليك في أقرب وقت ممكن'

  },
  WhatSchol:{
    WhatisScholigit:'ما هي scholigit؟',
    WhatisScholigitData:'منصة واحدة تقوم برقمنة جميع خدمات التعليم عبر تطبيقات الجوال والمواقع الإلكترونية',
    ProfuctOverview:'نظرة عامة على المنتج',
    Classroom:'نظام إدارة التعلم',
      Messages:'نظام إدارة الطلاب',
      Stories:' تخطيط موارد المؤسسة',
    Useropinions:'آراء المستخدم',
    With:'مع',
    FinallyAble:'لقد تمكنا أخيرًا من القيام بذلك العمل الذي ألهمنا لنصبح معلمين في  المكان الأول. لدينا أحلام كبيرة لدينا الطلاب وأنفسنا. وللأول   الوقت ، أشعر أنني صنعهم يحدث.',
    Stephanie:'ستيفاني سميث ، معلمة الصف الرابع في ولاية تينيسي',
    SCholigitMission:'مهمه scholigit',
    "SCholigitMissionData": "في سكولجيت، مهمتنا هي تمكين المؤسسات التعليمية من خلال تقديم منصة شاملة ومبتكرة تجمع بين حلول الذكاء الاصطناعي لتبسيط المهام الإدارية، وتعزيز تجارب التعلم، وتيسير التواصل بين الإداريين والمعلمين والطلاب وأولياء الأمور. نحن ملتزمون باستخدام أحدث التقنيات والذكاء الاصطناعي لإنشاء بيئات تعليمية فعالة وفعّالة وجذابة، لضمان منح كل طالب الفرصة لتحقيق كامل إمكاناته.",
    ABOUTUS:'معلومات عنا',
    "ABOUTUSData": "تأسست سكولجيت في عام ٢٠١٨ تحت مظلة شركة ديجيتال يارد، وهي مكرسة لإحداث ثورة في إدارة التعليم من خلال التكنولوجيا المبتكرة. منصتنا الشاملة تدمج بشكل سلس بين نظام إدارة التعلم (LMS) وحلول تخطيط موارد المؤسسة (ERP)، المصممة خصيصًا للمدارس والحضانات. تشمل ميزاتنا مواقع إلكترونية مخصصة لتقديم الطلاب والإعلانات الوظيفية، ووحدات شاملة للموارد البشرية والمحاسبة، وإشعارات الحافلات، والمحافظ الرقمية، والمتاجر الإلكترونية. تعمل سكولجيت على تبسيط الإدارة وتعزيز تجربة التعليم لكل من الإداريين، والمعلمين، والطلاب، وأولياء الأمور. اكتشف كيف يمكن لسكولجيت أن تجعل عمليات مؤسستك أكثر سلاسة وتركز على ما يهم حقًا - تقديم تعليم عالي الجودة.",
      ReadMore: 'اقرأ المزيد',
      "LMS": "# نظام إدارة التعلم (LMS)\n- فصول دراسية تفاعلية عبر الإنترنت: تسهيل إنشاء فصول دراسية افتراضية جذابة وتفاعلية.\n- إدارة الواجبات والتقييمات: تبسيط عملية إنشاء وتوزيع وتصحيح الواجبات والتقييمات.\n- متابعة تقدم الطلاب: مراقبة وتقييم أداء الطلاب وتقدمهم من خلال تحليلات وتقارير مفصلة.\n- مشاركة الموارد: تمكين المعلمين من مشاركة المواد التعليمية والمحتوى المتعدد الوسائط والموارد بكل سهولة.",
      "SMS": "# نظام إدارة الطلاب (SMS)\n- التقديمات والتسجيل: تبسيط عملية تقديم الطلاب والتسجيل من خلال طلبات إلكترونية وتدفقات عمل مؤتمتة.\n- تتبع الحضور: تسجيل ومراقبة حضور الطلاب باستخدام حلول رقمية فعالة.\n- بوابات الأهل والطلاب: توفير وصول الأهل والطلاب إلى الدرجات وسجلات الحضور والإعلانات المهمة.\n- أدوات التواصل: تعزيز التواصل بين المعلمين والطلاب وأولياء الأمور من خلال نظام رسائل وإشعارات متكامل.",
      "ERP": "# تخطيط موارد المؤسسة (ERP)\n- إدارة الموارد البشرية (HR): إدارة سجلات الموظفين والرواتب وتقييم الأداء والمزيد من خلال وحدة HR شاملة.\n- المحاسبة والمالية: الإشراف على العمليات المالية باستخدام أدوات للفوترة والميزنة وتتبع النفقات.\n- إدارة المخزون: متابعة مخزون المدرسة من المستلزمات والمعدات والأصول الأخرى.\n- إشعارات الحافلات: ضمان سلامة الطلاب من خلال إشعارات الحافلات في الوقت الفعلي وإدارة المسارات.\n- محفظة رقمية ومتجر إلكتروني: تسهيل المعاملات الآمنة لرسوم المدرسة والزي المدرسي والكتب وغيرها من خلال متجر إلكتروني متكامل ومحفظة رقمية."
  },
  notifi: {
    MarkAll: "تحديد قراءه الكل",
    NotifiContent: "مرحبًا بك مرة أخرى في لوحة إدارة مختبرات Starwallet",
    fiveMin: "خمسه دقائق",
    Delete: "مسح"
  },
  home: {
    exportExcel: "تحميل البيانات"
  },
  header: {
    Search: "بحث...",
    Editprofile: "تعديل الملف الشخصي",
    SUPERADMIN: "مدير",
    NOTIFICATIONS: "اشعارات",
    Seeall: "اظهار الكل"
  },
  errors: {
    Unauthorized: "غير مصرح",
    sorry401: "ناسف لا يمكن تنفيذ هذه العمليه",
    goToHome: "اذهب الي الصفحه الرئيسيه",
    sorry403: "نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.",
    Access: "غير مسموح بالدخول",
    notFound: "لم يتم الوصول للصفحه",
    error_text:
      "الصفحه التي تبحث عنها ربما تكون قد تم حذفهااو تم تغيير اسمها او غير متاحه حاليا",
    serverError: "خطا بالخادم"
  },
  MScreens: {
    LinkExpired: "انتهت صلاحية الرابط",
    plaeaseEnterMail:
      "الرجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.",
    email: "بريد الكتروني",
    mail_massage: "هذا البريد الالكتروني غير صالح",
    mail_req_massage: "الرجاء ادخال بريدك الالكتروني",
    ResendMail: "اعاده ارسال البريد الالكتروني",
    oops: "عفوا !!عنوان URLغير صحيح",
    Ok: "حسنا",
    PasswordReset: "تم إعادة تعيين كلمات المرور بنجاح",
    ResetPassword: "تعيين رقم سري جديد",
    NewPassword: "رقم سري جديد",
    passwordMassage: "الرجاء ادخال كلمه السر",
    passwordmin: "يجب أن تكون كلمة المرور أقل من 8 أحرف",
    passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
    passwordexpresion:
      "تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
    password_placeholder: "ادخل رقمك السري",
    confirmPassword: "تأكيد كلمة المرور الجديدة",
    confirm_massage: "الرجاء تاكيد كلمه السر",
    restPass_placeholder: "اعد كتابه رقم المرور",
    cancle: "الغاء",
    Reset: "إعادة تعيين",
    CheckInpoxp1: "يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في",
    CheckInpoxp2: "للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.",
    goToHome: "اذهب الي الصفحه الرئيسيه",
    backToSignin: "الرجوع الي تسجيل الدخول"
  },
  settings: {
    PasswordsChanged: "تم تغيير الرقم السري",
    successfullyDeleted: " تم الحذف بنجاح",
    ChangePassword: "تغيير الرقم السري",
    NewPassword: "رقم سري جديد",
    passwordMassage: "الرجاء ادخال كلمه السر",
    passwordmin: "يجب أن تكون كلمة المرور أقل من 8 أحرف",
    passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
    passwordexpresion:
      "تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
    password_placeholder: "ادخل رقمك السري",
    confirmPassword: "تأكيد كلمة المرور الجديدة",
    confirm_massage: "الرجاء تاكيد كلمه السر",
    restPass_placeholder: "اعد كتابه رقم المرور",
    logout: "تسجيل الخروج",
    AccountDeactivated: "تم تعطيل الحساب",
    YourAccountDeactivated:
      "تم إلغاء تنشيط حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك",
    YesSave: "نعم, احفظ",
    NoSave: "لا تحفظ",
    Cancel: "الغاء",
    Delete: "مسح",
    SaveChanges: "حفظ التغييرات",
    AreYouSoure: "هل تريد حفظ البيانات ؟",
    signIn: "تسجيل الدخول",
    EnterPassFor: "الرجاء ادخال الرقم السري الخاص ب",
    password: "الرقم السري",
    passwordMassage: "الرجاء ادخال كلمه السر",
    passwordmin: "يجب أن تكون كلمة المرور أقل من 8 أحرف",
    passwordmax: "يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى",
    passwordexpresion:
      "تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا",
    password_placeholder: "ادخل رقمك السري"
  },

  setting: {
    Settings: "الإعدادات",
    Integratedappsmasterata: "التطبيقات المتكاملة البيانات الرئيسية",
    GoogleRECAPTCHA: "جوجل RECAPTCHA",
    GoogleAnalyticsTrackingCode: "جوجل تتبع تحليلات مدونة",
    FacebookAppID: "معرف تطبيق Facebook",
    FacebookAppSecret: "Facebook App Secret",
    LinkedInAppID: "لينكدين معرف التطبيق",
    EmailedLinksSettings: "إعدادات ارتباطات بالبريد الإلكتروني",
    Emailverification: "تأكيد بواسطة البريد الالكتروني",
    Enable: "مكن",
    Emailverificationlinkexpirytimeminutes:
      "وقت انتهاء صلاحية التحقق من البريد الإلكتروني (بالدقائق)",
    Mins: "الدقائق",
    EmailNotifications: "اشعارات البريد الالكتروني",
    Resetpasslinkverificationlinkexpirytimeminutes:
      "إعادة تعيين رابط انتهاء صلاحية رابط التحقق (بالدقائق)",
    Resendmailinterval: "إعادة إرسال الفاصل الزمني للبريد",
    Tries: "المحاولات",
    Xplacholder: "xxxx-xxxx-xxxx"
  },

  Privileges: {
    AddRole: "إضافة دور",
    Newrole: "دور جديد",
    Personalinfo: "معلومات شخصية",
    Rolename: "اسم الدور",
    ISACTIVE: "نشط",
    Hidetranslations: "إخفاء الترجمات",
    TranslateIntoAr: "ترجم إلى “عربي“",
    TranslateIntoFr: 'ترجمة إلى "اللغة الفرنسية"',
    Enable: "مكن",
    Rolestatus: "حالة الدور",
    Privileges: "امتيازات",
    Privilege: "امتيازات",
    AddPrivileges: "إضافة الامتيازات",
    SelectPrivileges: "اختر الامتيازات",
    PleaseselectPrivilegestoAssign: "يرجى اختيار الامتيازات لتخصيصها",
    AssignedPrivileges: "الامتيازات المخصصة",
    Admins: "المسؤولين",
    AddAdmins: "إضافة المسؤولين",
    SelectAdmins: "حدد المسؤولين",
    PleaseselectAdminstoAssign: "يرجى اختيار المسؤولين لتعيين",
    deletedSuccessfully: "تم حذف الأدوار بنجاح",
    editedSuccessfully: "تم تعديل الدور بنجاح",
    addedSuccessfully: "تم إضافة دور جديد بنجاح"
  },

  currency: {
    Currencyname: "اسم العمله",
    Addcurrency: "اضف عمله",
    Newcurrency: "عمله جديده",
    currencyinfo: "معلومات العمله"
  },

  users: {
    USERS: "المستخدمين",
    Name: "الاسم",
    Age: "السن",
    Email: "البريد الإلكتروني",
    Address: "العنوان",
    Admins: "المسؤولين",
    UpdateAdmins: "تعديل المسؤولين",
    Adduser: "إضافة مسؤول",
    Personalinfo: "معلومات شخصية",
    Rules: "قواعد",
    Addrule: "أضف القاعدة",
    Selectrule: "اختر القاعدة",
    PleaseselectruletoAssign: "يرجى اختيار قاعدة لتعيين",
    Assignedrules: "القواعد المخصصة",
    deletedSuccessfully: "تم حذف المستخدمين بنجاح",
    editedSuccessfully: "تم تعديل المستخدم بنجاح",
    addedSuccessfully: "تم إضافة مستخدم جديد بنجاح"
  },
  grid: {
    New: "جديد ",
    goTo: "الذهاب لصفحة"
  },
  buttons: {
    Close: "أغلق",
    SaveNew: "حفظ و جديد",
    Save: "حفظ",
    UpdateNew: "تحديث و جديد",
    Update: "تحديث",
    Duplicate: "كرر",
    Delete: "حذف",
    remove: "حذف",
    Archive: "أرشيف",
    Edit: "تعديل",
    confirm: "هل أنت متأكد من أن تقوم بال",
    selectToEdit: "يرجى إختيار صف للتعديل",
    selectToDelete: "يرجى إختيار صف أو أكثر للحذف",
    change: "تغيير",
    add: "إضافة",
    send: "ارسال",
    cancel: "إلغاء",
    details: "التفاصيل",
    filter: "فلتر",
    reset: "إعادة تعيين"
  },

  inputs: {
    inputrequired: "الحقل مطلوب",
    PleaseinputyourFirstname: "يرجى إدخال اسمك الأول!",
    PleaseinputyourMiddlename: "الرجاء إدخال اسمك الأوسط!",
    PleaseinputyourLastName: "يرجى إدخال اسم العائلة الخاص بك!",
    Pleaseinputyourphonenumber: "يرجى إدخال رقم هاتفك!",
    mustbemin: "يجب أن يكون 3 أحرف على الأقل",
    cantaccept: "لا يمكن أن يقبل مساحة",
    specialcharacters: "لا يمكن أن يقبل الاسم الأول أحرفًا خاصة",
    acceptnumbers: "لا يمكن أن يقبل الاسم الأول الأرقام",
    Firstname: "الاسم الاول",
    Middlename: "الاسم الاوسط",
    LastName: "اسم العائله",
    FirstnameH: "مثال: احمد",
    MiddlenameH: "مثال: محمد",
    LastNameH: "مثال: كامل",
    PhoneNumber: "01xxxxxxxxx :مثال",
    phone: "هاتف",
    Email: "البريدالالكتروني",
    EmailH: "Ex: mail@Ex.com",
    TheinputisnotvalidEmail: "الإدخال غير صالح البريد الإلكتروني!",
    PleaseinputyourEmail: "يرجى إدخال البريد الإلكتروني الخاص بك!",
    EmailAlreadyExists: "البريد الالكتروني موجود بالفعل",
    Start: "يبدأ",
    End: "ينتهي",
    Firstnamemustcharacters: "يجب أن يكون الاسم  3 أحرف على الأقل",
    namecantacceptspace: "الاسم لا يمكن أن يقبل مسافة",
    Namecanacceptspecialcharacters: "لا يمكن أن يقبل الاسم الأحرف الخاصة",
    Namecanacceptnumbers: "الاسم لا يمكن أن يقبل الأرقام"
  },
  tooltip: {
    tocontinuepleasesavefirst: "للمتابعة يرجى حفظ أولا"
  },
  masterData: {
    basicInfo: "البيانات الأساسية",
    label: "البيانات الرئيسية",
    add: "إضافة",
    name: "الإسم",
    isActive: "هل مفعل ؟",
    active: "مفعل",
    inActive: "غير مفعل",
    translate: "الترجمة",
    hideTranslations: "إخفاء الترجمة",
    namePlaceholder: "ضع الإسم هنا",
    nameTranslationPlaceholder: "ضع ترجمة الإسم هنا",
    deletedSuccessfully: "تم حذف البيانات بنجاح",
    editedSuccessfully: "تم تعديل البيانات بنجاح",
    addedSuccessfully: "تم إضافة البيانات بنجاح"
  },
  countries: {
    basicInfo: "البيانات الأساسية",
    label: "بلد",
    deletedSuccessfully: "تم حذف البلاد بنجاح",
    editedSuccessfully: "تم تعديل البلد بنجاح",
    addedSuccessfully: "تم إضافة بلد جديد بنجاح",
    nullFlag: "يرجى إختيار صورة",
    countryCurrency: "عملة البلد",
    currencyPlaceholder: "إختر عملة البلد",
    countryCodePlaceholder: "إدخل كود البلد, مثال: +20",
    countryCode: "كود البلد"
  },
  governorates: {
    basicInfo: "البيانات الأساسية",
    label: "محافظة",
    deletedSuccessfully: "تم حذف المحافظات بنجاح",
    editedSuccessfully: "تم تعديل المحافظة بنجاح",
    addedSuccessfully: "تم إضافة محافظة جديدة بنجاح"
  },
  sideBar: {
    Admin: "المديرون",
    Roles: "الأدوار",
    Schools: "المدارس",
    MasterData: "البيانات الرئيسية",
    Setting: "الإعدادات",
    Countries: "البلاد",
    Positions: "المناصب",
    PaymentMethods: "وسائل الدفع",
    Currency: "العملات",
    Governorates: "المحافظات",
    Sectors: "القطاعات",
    Teams: "الفرق",
    Governorates: "المحافظات",
    Classes: "الفصول",
    Teachers: "المدرسين",
    Students: "الطلاب",
    Installments: "الاقساط",
    News: "الأخبار",
    LostFound: "المفقودات",
    Notifications: "التنبيهات"
  },
  filtration: {
    division: "القطاع",
    level: "المرحلة",
    grade: "الصف",
    searchBy: "البحث بإسم",
    school: "المدرسة",
    template: "نموذج الأقساط",
    teacher: "المدرس",
    student: "الطالب",
    class: "الفصل",
    item: "العنصر",
    notification: "التنبيهة",
    news: "الخبر"
  },
  uploader: {
    sizeExceeded: "حجم الصورة يجب أن يكون أقل من 2 ميجا بايت",
    dimensionDoesntMatch: "أبعاد الصورة يجب أن تكون على الأقل 200 * 200"
  },
  Classes: {
    Students: "الطلاب",
    Toolkit: "أدوات",
    Homework: "واجب منزلي",
    Attendance: "الحضور",
    SelectMultiple: "اختيار متعدد",
    selectSubject: "إختر المادة",
    SetHomework: "تعيين الواجبات المنزلية",
    UpdateHomework: "تحديث الواجبات المنزلية",
    DeleteHomework: "حذف الواجبات المنزلية",
    Random: "عشوائية",
    Timer: "مؤقت",
    inviteParents: "دعوة الآباء",
    HelpingOther: "مساعدة الآخرين",
    OnTask: "في مهمة",
    participating: "المشاركة",
    TeamWork: "مجموعة عمل",
    WorkingHard: "اعمل بجد",
    Givefeedbackto: "إعطاء تغذية مرتدة ل",
    Positive: "إيجابي",
    NeedsWork: "يحتاج للدعم",
    Saveattendance: "حفظ الحضور",
    Makeallpresent: "جعل الكل حاضرين",
    MakeallAbsent: "اجعل الكل غائبا",
    Classroom: "قاعة الدراسة",
    ClassStory: "قصة الفصل",
    Profile: "الملف الشخصي",
    Messages: "رسائل ",
    selectAll: "حدد الكل",
    to: "الى:",
    NewMessage: "رسالة جديدة",
    Cancel: "إلغاء",
    next: "التالى",
    send: "ارسال",
    writethemessagetitle: "اكتب عنوان الرسالة",
    writeyourmessagehere: "اكتب رسالتك هنا…",
    WholeClass: "كل الفصل",
    SetHomework: "تعيين الواجبات المنزلية",
    Cancel: "إلغاء",
    Wet: "مبلل",
    Dry: "جاف",
    BM: "BM"
  },
  Home: {
    LastUpdated: "آخر تحديث",
    Students: "الطلاب",
    Mood: "الحاله المزاجيه",
    Presence: "حضور",
    Division: "قطاع",
    ConnectParent: "الاتصال بولي الامر"
  },
  ClassStory: {
    edit: "تعديل"
  },
  messages: {
    Messages: "الرسائل",
    Message: "رساله",
    NewMessage: "رسالة جديدة",
    AllParents: "جميع الآباء والأمهات",
    Send: "إرسال",
    Messageparent: "مراسله ولي الامر",
    writeyourmessagehere: "اكتب رسالتك هنا…"
  },
  timeline: {
    addPhoto: "أضف صورة",
    addVideo: "أضف فيديو",
    post: "إرسال",
    createTagline: "ماذا يحدث في فصلك؟",
    captionRequired: "نص المنشور مطلوب",
    postCreatedSuccessfully: "تم إضافة المنشور بنجاح"
  },
  puplicHome: {
    home: "الرئيسية",
    aboutus: "معلومات عنا",
    contactUs: "تواصل معنا",
    support: "الدعم",
    faq: "التعليمات",
    privacy:"السياسه",
    ForParents: "للوالدين",
    ForSchools: "للمدارس",
    SchoolAdmin: "إدارة المدرسة",
    RequestDemo: "طلب عرض توضيحي",
    OurSchool: "مدرستنا",
    CompleteSolution: "الحل الكامل",
    ReadMore: "اقرأ أكثر",
    Manageyour: "إدارة فصلك ",
    Classwithschologit: "scholigit عن طريق ",
    ParentsSolutions: "حلول الآباء",
    TeachersSolution: "حل المعلمين",
    Fullname: "الاسم بالكامل",
    email: "البريد الالكترونى",
    phone: "رقم الاتصال",
    writeMessage: "من فضلك اكتب رسالتك",
    Allareconnected: "الجميع على إتصال ",
    withScholigit: "Scholigit مع   ",
    sliderOne:
      ": تتمتع Scholigit بمزايا للمدرسة / الحضانة والمعلمين وأولياء الأمور التي تساعد كل واحد منهم علىإرضاء أةلياء الأمور وتسريع عمليات الاتصال.  ",
    Studentmanagement: "نظام إدارة ",
    system: "الطلاب",
    sliderTwo:
      ": نظام إدارة الطلاب مع تقارير حضور وغياب الطلاب والجدول الزمني للفصل.",
    Trackallfees: "تتبع جميع الرسوم",
    sliderThree:
      "الآن يمكن تتبع جميع الرسوم الخاصة بالطلاب (الأقساط -رسوم اخرى) وعمل تقارير بها بسهولة للمدرسة/الحضانة أو لولي الأمر.",
    Notificationandmessaging: "الإخطارات والرسائل",
    sliderFour:
      "أرسل رسالة وإخطار لأولياء الأمور أو المعلمين باستخدام نظام المراسلة والإخطار الخاص بنا.",
    OurSchoolText:
      "تقوم Scholigit بتسهيل التفاعلات بين المدرسة / الحضانة والمعلمين وأولياء الأمور من خلال ربط جميع الأطراف على منصة موحدة.",
    schoolAdminText:
      "Scholigit هي فائدة لإدارة المدرسة / الحضانة لتكون على اتصال أكثر مع أولياء الأمور والمعلمين والفصول الدراسية من خلال نظام إدارة الطلاب الفعال لدينا.",
    parentText:
      "يمكن لأولياء الأمور الآن متابعة أطفالهم خطوة بخطوة في الفصول الدراسية وتتبع تقدمهم من خلال التفاعل المباشر مع المدرس.",
    teacherText:
      "سيدير ​​المعلمون أيضًا فصولهم ويقومون بعملهم بسهولة دون إضاعة الوقت عن طريق إخبار أولياء الأمور بحالات أطفالهم وتقاريرهم من خلال تطبيق المعلم أو بوابة الموقع الألكتروني. ",
    contactUsText: "يسعدنا تلقي تعليقاتك واستفساراتك وسنتواصل معك فى اقرب وقت.",
    Submit: "إرسال",
    visitTeacherPortal: 'تطبيق المعلم',
    ortanizationType: "نوع المنظمة",
    school:"مدرسة",
    Nursery:"حضانة",
    Other:"أخرى",
    WeBringTheUltimateFor:"نأتي الحل النهائي ل",
    Parents:"الآباء",
    School:"مدرسة",
    Communication:"الاتصالات",
    requiredField:"هذا الحقل مطلوب",
    scrollup:"انتقل إلى أعلى",
    dressOne:"17 شارع الأهرام ، مصر الجديدة ، القاهرة ، مصر. الطابق الثالث ، المدخل ب",
    dressTwo:"مكتب رقم 2908 برج المنارة ، الخليج التجاري ، دبي ، الإمارات العربية المتحدة.",
    scrolldown:"انتقل الى الاسفل"
  }
};
