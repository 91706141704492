import React from 'react'
// import Lottie from 'react-lottie'
import BaseComponent from '../BaseComponent/BaseComponent.js';

class LoadingIndicator extends BaseComponent {
    constructor(props){
        super(props)
        this.state = {

        }
    }
    
    render(){

        // const defaultOptions = {
        //     loop: true,
            
		// 	autoplay: true,
		// 	animationData: require('../../../../../resources/loading.json'),
		// 	rendererSettings: {
		// 		preserveAspectRatio: "xMidYMid slice"
		// 	}
        // };

        return(
            <div>
              <div className="lds-dual-ring"></div>
                {/* <Lottie options={defaultOptions}/> */}
            </div>
        )
    }
}

export default LoadingIndicator