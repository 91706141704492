/**
 * Import action types here
 */
import { 
    LIST_ADMINS, 
    ADD_ADMIN, 
    DELETE_ADMIN, 
    EDIT_ADMIN, 
    LIST_ROLES, 
    ADD_ROLE, 
    DELETE_ROLE, 
    DELETE_CURRENCY, 
    ADD_CURRENCY, 
    LIST_SCHOOLS,
    DELETE_SCHOOLS, 
    LIST_CURRENCIES, 
    LIST_MASTER, 
    SET_MASTER_ACTIVE_TYPE, 
    ADD_DATA,
    DELETE_DATA,
    LIST_COUNTRIES,
    DELETE_COUNTRY,
    LIST_GOVERNORATES,
    SET_ACTIVE_COUNTRY,
    ADD_GOVERNORATE,
    DELETE_GOVERNORATE,
    UPDATE_LOCATION
} from "./action-types";

/**
 * Module internal initial state
 */
const initialState = {
    adminsList: [],
    dataList: [],
    rolesList: [],
    schoolsList : [],
    currenciesList : [],
    countriesList: [],
    governoratesList: [],
    rolesTotalCount: null,
    schoolsTotalCount: null,
    CurrenciesTotalCount: null,
    totalCount: null,
    masterTotalCount: null,
    activeMasterType: null,
    countriesTotalCount: null,
    governoratesTotalCount: null,
    activeCountry: null,
    currentLocation: null,
};  


/**
 * Checks dispatched actions and updates state accordingly
 * @param {Object} state 
 * @param {Object} action 
 * @returns {Function} 
 */

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case LIST_ADMINS:
            return listAdmins(state, payload)
        case ADD_ADMIN: 
            return addAdmin(state, payload)
        case DELETE_ADMIN : 
            return deleteAdmin(state, payload)
        case EDIT_ADMIN: 
            return editAdmin(state, payload)
        case LIST_ROLES: 
            return listRoles(state, payload)
        case LIST_SCHOOLS: 
            return listSchools(state, payload)
        case DELETE_SCHOOLS: 
            return deleteSchool(state, payload)
        case LIST_CURRENCIES: 
            return listCurrencies(state, payload)
        case ADD_ROLE: 
            return addRole(state, payload)
        case ADD_CURRENCY: 
            return addCurrency(state, payload)
        case DELETE_ROLE: 
            return deleteRole(state, payload)
        case DELETE_CURRENCY: 
            return deleteCurrency(state, payload)
        case LIST_MASTER: 
            return listMaster(state, payload)
        case SET_MASTER_ACTIVE_TYPE:
            return setMasterActiveType(state, payload)
        case ADD_DATA: 
            return addData(state, payload)
        case DELETE_DATA: 
            return deleteData(state, payload)
        case LIST_COUNTRIES:
            return listCountries(state, payload)
        case DELETE_COUNTRY:
            return deleteCountry(state, payload)
        case LIST_GOVERNORATES:
            return listGovernorates(state, payload)
        case SET_ACTIVE_COUNTRY:
            return setActiveCountry(state, payload)
        case ADD_GOVERNORATE:
            return addGovernorate(state, payload)
        case DELETE_GOVERNORATE:
            return deleteGovernorate(state, payload)
        case UPDATE_LOCATION: 
            return updateLocation(state, payload)
        default:
            return state;
    }
} 

/**
 * Returns an updated version of the state based on the action
 * @param {Object} state 
 * @param {Object} payload 
 * @return {Object} state
 */
function listAdmins(state, payload) {
    return {
      ...state, adminsList: payload.data, totalCount: payload.total
    }
}

function addAdmin(state, payload) {
    return {
        ...state, adminsList: [payload, ...state.adminsList], totalCount: state.totalCount + 1
    }
}

function deleteAdmin(state, payload) {
    return {
        ...state,
        adminsList : state.adminsList.filter(user => user.adminID !== payload), 
        totalCount: state.totalCount - 1
    }
}

function editAdmin(state, payload) {
    let index = state.adminsList.findIndex(admin => admin.adminID === payload.adminID)
    let updatedList = state.adminsList
    updatedList[index].name = payload.firstName
    updatedList[index].email = payload.email
    updatedList[index].adminID = payload.adminID

    return {
        ...state, adminsList: updatedList
    }
}

function listRoles(state, payload) {
    return {
        ...state, rolesList: payload.data.dataList, rolesTotalCount: payload.data.totalCount  
    }
}

function listSchools(state, payload) {
    return {
        ...state, schoolsList: payload.data.schoolsList, schoolsTotalCount: payload.data.totalCount  
    }
}

function listCurrencies(state, payload) {
    return {
        ...state, currenciesList: payload.data.dataList, currenciesTotalCount: payload.data.totalCount  
    }
}

function addRole(state, payload){
    return {
        ...state, rolesList: [payload, ...state.rolesList], rolesTotalCount: state.rolesTotalCount + 1
    }
}

function addCurrency(state, payload){
    console.log(state, payload)
    return {
        ...state, currenciesList: [payload, ...state.currenciesList], currenciesTotalCount: state.currenciesTotalCount + 1
    }
}

function deleteRole(state, payload) {
    return {
        ...state,
        rolesList : state.rolesList.filter(role => role.id !== payload), 
        rolesTotalCount: state.rolesTotalCount - 1
    }
}

function deleteSchool(state, payload) {
    return {
        ...state,
        schoolsList : state.schoolsList.filter(role => role.id !== payload), 
        rolesTotalCount: state.rolesTotalCount - 1
    }
}

function deleteCurrency(state, payload) {
    return {
        ...state,
        currenciesList : state.currenciesList.filter(role => role.id !== payload), 
        currenciesTotalCount: state.currenciesTotalCount - 1
    }
}

function listMaster(state, payload) {
    return {
      ...state, dataList: payload.data, masterTotalCount: payload.total
    }
}

function setMasterActiveType(state, payload) {
    return {
        ...state, activeMasterType: payload
    }
}

function addData(state, payload){
    return {
        ...state, dataList: [payload, ...state.dataList], masterTotalCount: state.masterTotalCount + 1
    }
}

function deleteData(state, payload) {
    return {
        ...state,
        dataList : state.dataList.filter(data => data.id !== payload), 
        masterTotalCount: state.masterTotalCount - 1
    }
}

function listCountries(state, payload) {
    return {
      ...state, countriesList: payload.data, countriesTotalCount: payload.total
    }
}

function deleteCountry(state, payload) {
    return {
        ...state,
        countriesList : state.countriesList.filter(data => data.id !== payload), 
        countriesTotalCount: state.countriesTotalCount - 1
    }
}

function listGovernorates(state, payload) {
    return {
      ...state, governoratesList: payload.data, governoratesTotalCount: payload.total
    }
}

function setActiveCountry(state, payload) {
    return {
        ...state, activeCountry: payload
    }
}

function addGovernorate(state, payload){
    return {
        ...state, governoratesList: [payload, ...state.governoratesList], governoratesTotalCount: state.governoratesTotalCount + 1
    }
}

function deleteGovernorate(state, payload) {
    return {
        ...state,
        governoratesList : state.governoratesList.filter(data => data.id !== payload), 
        governoratesTotalCount: state.governoratesTotalCount - 1
    }
}

function updateLocation(state, payload){
    return {
        ...state, currentLocation: payload
    }
}

export default reducer