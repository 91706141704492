import React from 'react'
import Loadable from 'react-loadable'


const routes = [
    {
        path: '/resetPassword',
        component: Loadable({
            loader: () => import('./components/ResetPassword/ResetPassword'),
            loading: () => (<h1>Loading component here</h1>)
        })
    },
    {
        path: '/signInForget',
        component: Loadable({
            loader: () => import('./components/SigninForget/SigninForget'),
            loading: () => (<h1>Loading component here</h1>)
        })
    },

    {
        path: '/resetPasswordDone',
        component: Loadable({
            loader: () => import('./components/ResetDone/ResetDone'),
            loading: () => (<h1>Loading component here</h1>)
        })
    },

    {
        path: '/linkExpired',
        component: Loadable({
            loader: () => import('./components/LinkExpired/LinkExpired'),
            loading: () => (<h1>Loading component here</h1>)
        })
    },

]

export default routes