import {
    AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT, SET_LOCALE, UPDATE_CONNECTION_STATUS
} from './action-types'
import { en } from '../../../../resources/en';
import { ar } from '../../../../resources/ar';


/**
 * Module internal initial state
 */
const initialState = {
    isAuthenticated: false,
    token: null,
    refreshToken: null,
    token_expiry: null,
    userRoles: null,
    currentLocale: null,
    currentResource: null,
    connectionStatus: false,
};  


/**
 * Check dispatched action and update state accordingly
 * @param {Object} state 
 * @param {Object} action 
 * @returns {Function} 
 */

const reducer = (state = initialState, {type, payload = null}) => {
    switch (type) {
        case AUTH_LOGIN:
            return login(state, payload)
        case AUTH_CHECK: 
            return checkAuth(state)
        case AUTH_LOGOUT: 
            return logout(state) 
        case SET_LOCALE: 
            return setLocale(state, payload)
        case UPDATE_CONNECTION_STATUS: 
            return updateConnectionStatus(state, payload)
        default:
            return state;
    }
} 

function login(state, payload) {
    window.sessionStorage.setItem('access_token', payload.token);
    window.sessionStorage.setItem('roles', JSON.stringify(payload.roles));
    window.sessionStorage.setItem('refreshToken', payload.refreshToken);
    window.sessionStorage.setItem('token_expiry', payload.token_expiry);
    if(payload.rememberMe){
        localStorage.setItem('access_token', payload.token);
        localStorage.setItem('roles', JSON.stringify(payload.roles));
        localStorage.setItem('refreshToken', payload.refreshToken);
        localStorage.setItem('token_expiry', payload.token_expiry);
    }
    return {
      ...state, isAuthenticated: true, token: payload.token, userRoles: payload.roles, refreshToken: payload.refreshToken, token_expiry: payload.token_expiry
    }
}

function checkAuth(state) {
    state = Object.assign({}, state, {
      isAuthenticated: !!localStorage.getItem('access_token') || !!window.sessionStorage.getItem('access_token'),
      token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : window.sessionStorage.getItem('access_token'),
      userRoles: localStorage.getItem('roles') ? JSON.parse(localStorage.getItem('roles')) : JSON.parse(window.sessionStorage.getItem('roles'))
    })
  
    return state;
}

function logout(state){
    window.sessionStorage.removeItem('access_token');
    window.sessionStorage.removeItem('roles');
    window.sessionStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('token_expiry');
    localStorage.removeItem('access_token');
    localStorage.removeItem('roles');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token_expiry');
    
    return {
        ...state, isAuthenticated: false, token: null, userRole: null
    }
}

function setLocale(state, payload){
    return {
        ...state, currentLocale: payload, currentResource: payload === 'english' ? en : ar
    }
}

function updateConnectionStatus(state, payload){
    return {
        ...state, connectionStatus: payload
    }
}

export default reducer