export const LIST_ADMINS = 'LIST_ADMINS'
export const ADD_ADMIN = 'ADD_ADMIN'
export const DELETE_ADMIN = 'DELETE_ADMIN'
export const EDIT_ADMIN = 'EDIT_ADMIN'
export const LIST_ROLES = 'LIST_ROLES'
export const ADD_ROLE = 'ADD_ROLE'
export const ADD_DATA = 'ADD_DATA'
export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_CURRENCY = 'DELETE_CURRENCY'
export const LIST_SCHOOLS = 'LIST_SCHOOLS'
export const DELETE_SCHOOLS = 'DELETE_SCHOOLS'
export const LIST_CURRENCIES = 'LIST_CURRENCIES'
export const ADD_CURRENCY = 'ADD_CURRENCY'
export const LIST_MASTER = 'LIST_MASTER'
export const SET_MASTER_ACTIVE_TYPE = 'SET_MASTER_ACTIVE_TYPE'
export const DELETE_DATA = 'DELETE_DATA'
export const LIST_COUNTRIES = 'LIST_COUNTRIES'
export const DELETE_COUNTRY = 'DELETE_COUNTRY'
export const LIST_GOVERNORATES = 'LIST_GOVERNORATES'
export const SET_ACTIVE_COUNTRY = 'SET_ACTIVE_COUNTRY'
export const ADD_GOVERNORATE = 'ADD_GOVERNORATE'
export const DELETE_GOVERNORATE = 'DELETE_GOVERNORATE'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'

export default {
    LIST_ADMINS,
    ADD_ADMIN,
    DELETE_ADMIN,
    EDIT_ADMIN,
    LIST_ROLES,
    ADD_ROLE,
    DELETE_ROLE,
    DELETE_CURRENCY,
    LIST_SCHOOLS,
    DELETE_SCHOOLS,
    LIST_CURRENCIES,
    ADD_CURRENCY,
    LIST_MASTER,
    SET_MASTER_ACTIVE_TYPE,
    ADD_DATA,
    DELETE_DATA,
    LIST_COUNTRIES,
    DELETE_COUNTRY,
    LIST_GOVERNORATES,
    SET_ACTIVE_COUNTRY,
    ADD_GOVERNORATE,
    DELETE_GOVERNORATE,
    UPDATE_LOCATION
}