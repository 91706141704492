import React from 'react'
import Loadable from 'react-loadable'
import LoadingIndicator from '../Common/components/LoadingIndicator/LoadingIndicator'
 
/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const routes = [
    {
        path: '/',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./components/Home/Home'),
            loading: () => (<LoadingIndicator/>)
        })
    },{
        path: '/sharing',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./components/Sharing/Sharing'),
            loading: () => (<LoadingIndicator/>)
        })
    }, {
        path: '/d',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./components/DownloadRedirect/DownloadRedirect'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/download',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./components/DownloadRedirect/DownloadRedirect'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/faq',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./views/FAQ'),
            loading: () => (<LoadingIndicator/>)
        })
    },

    {
        path: '/aboutUs',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./views/AboutUs'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/faq/:name',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./components/FAQContent/FAQContent'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/MeetingEnded',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./components/ThankU/ThankU'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/privacy',
        exact: true,
        isAuth: false,
        component: Loadable({
            loader: () => import('./views/privacy/privacy'),
            loading: () => (<LoadingIndicator/>)
        })
    },

]

export default routes