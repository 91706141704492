import React from 'react'
import { notification } from 'antd';

class BaseComponent extends React.Component {

	/**
	 * 
	 * @param {string} type 'error' | 'success' 
	 * @param {[]} messages 
	 */
    openNotification = (type, messages) => {
		let locale = localStorage.getItem('currentLocale')
		let headline = locale === 'english' ? (type === "error" ? "Error occured" : "Successful operation") : 
		(type === "error" ? "هناك خطأ" : "عملية ناجحة") 
		messages.forEach(message => {
			notification[type]({
				message: headline,
				description: message
			});
		});
	};
}

export default BaseComponent