export const en = {
  auth: {
    login: "login",
    logout: "Logout",
    rememberMe: "Remember me",
    signIn: "Sign In",
    email: "Email",
    mail_massage: "The input is not valid E-mail!",
    mail_req_massage: "Please input your E-mail!",
    password_placeholder: "Enter your password",
    plaeaseEnterMail:
      "Please Enter your email then click the button below to request another one.",
    password: "Password",
    forgetPassword: "Forget Password?",
    Forget_Password: "Forget Password",
    sammary:
      "Please enter your email so that we can  send you a rest password email.",
    passwordMassage: "Please input your password!",
    backToSignin: "Back to sign in",
    sendMail: "Send Email",
    sorry505:
      "The server encountered an internal error or misconfiguration and  was  unable to complete your request",
    resetSent: "Reset code was sent successfully",
    confirmPassword: "Confirm new password",
    restPass_placeholder: "Confirm your password",
    confirm_massage: "Please confirm your password!",
    Reset: "Reset",
    cancle: "Cancle",
    CheckInpoxp1: "Please check your inbox or spam folder at",
    CheckInpoxp2: "for a link to reset your password .",
    GoToMail: "Go to Your Mail",
    Ok: "OK",
    oops: "Oops!! This URL Is Not Valid Anymore.",
    ResendMail: "Resend Mail",
    passwordmin: "password must be min 8 characters",
    passwordmax: "password must be max 20 characters",
    passwordexpresion:
      "Password expresion that requires one lower case letter, one upper case letter, one digit",
    profile: "Profile",
    personalInfo: "Personal Information",
    name: "Name",
    nameMassage: "Please input your name!",
    namePlaceholder: "Enter your name",
    Security: "Security",
    Account: "Account",
    Change: "Change",
    Deactivate: "Deactivate",
    DeactivateAccount: "Deactivate account",
    SaveChanges: "Save changes",
    ChangePassword: "CHANGE PASSWORD",
    NoCancel: "No, Cancel",
    YesDeactivate: "Yes, Deactivate",
    PasswordsChanged: "Passwords changed successfully",
    AccountDeactivated: "Account Deactivated!",
    YourAccountDeactivated:
      "Your account have be deactivated, please be informed that you’ll never be able to login again or restore your account",
    Delete: "Delete",
    AreYouSoure: "Are you sure you want to delete this data ?",
    YesSave: "Yes, Save",
    NoSave: "No, Don’t Save",
    NewPassword: "New password",
    Cancel: "Cancel",
    EnterPassFor: "Please enter Your Password for",
    TimedOut:
      "You are being timed-out due to inactivity .please choose to stay ",
    SinginOrLogout: "Signed-in or Log-out .",
    otherwise: "otherwise , you will be logged out automatically",
    minute: "after 1 minute.",
    stay: "Stay logged-in",
    inactivityMessage: "You have been logged-out due to inactivity",
    Singinyouraccount: "Sing in Now to your account"
  },
  notifi: {
    MarkAll: "Mark all reeded",
    NotifiContent: "Welcome back to Starwallet labs admin panel",
    fiveMin: "5 Mins ago",
    Delete: "Delete"
  },
  header: {
    Search: "Search...",
    Editprofile: "Edit profile",
    SUPERADMIN: "SUPER ADMIN",
    NOTIFICATIONS: "NOTIFICATIONS",
    Seeall: "See all"
  },
  errors: {
    Unauthorized: "Unauthorized",
    sorry401: "Sorry , your request could not be processed.",
    goToHome: "Go To Home page",
    sorry403:
      "We are sorry , but you do not have access to this page or resource.",
    Access: "Access Denied",
    notFound: "Page Not Found",
    error_text:
      " The page you’re looking for might have been removed, had its name changed or is temporarily unavailable.",
    serverError: "Internal Server Error"
  },

  FAQ:{
    SearchforyourAnswer:'Search for your Answer',
    QuickAnswers:'Quick Answers',
    PopularQuestions:'Popular Questions',
    writeyourquestionher:"write your question here…",
    ExploreMoreAnswers:'Explore More Answers',
    ToImproveandkeepourservice:'To Improve and keep our service..',
    Incaseyou:'In case you didn’t find the answer you look for, you can send us your Question and we’ll answer you soon.',
    selectacategory:'select a category..',
    sendusyourquestionhere:'send us your question here…',
    CanIDelete:'Can i delete my account whenever i want ?',
    successfullySubmitedQuestion: 'You have successfully submitted a question, we will get back to you as soon as possible'
  },
  WhatSchol:{
    WhatisScholigit:'What is Scholigit ?',
    WhatisScholigitData:'One platform that digitizes all educational services SMS/LMS mobile-app/web.',
    ProfuctOverview:'Product Overview',
      Classroom:'Learning Management System',
      Messages:'Student Management System',
      Stories:'Enterprise Resource Planning',
    Useropinions:'User’s opinions',
    With:'With',
    FinallyAble:'we’re finally able to do the work that inspired us to become teachers in the first place. We have BIG dreams for our students and ourselves. And for the first time, I feel like I’m making them happen.',
    Stephanie:'Stephanie Smith, 4th grade teacher in Tennessee',
    SCholigitMission:'SCholigit’s Mission',
    SCholigitMissionData:'At Scholigit, our mission is to empower educational institutions by providing an all-in-one, innovative platform that integrates AI-driven solutions to streamline administrative tasks, enhance learning experiences, and foster seamless communication among administrators, teachers, students, and parents. We are dedicated to leveraging cutting-edge technology and artificial intelligence to create efficient, effective, and engaging educational environments, ensuring every student has the opportunity to achieve their full potential',
    ABOUTUS:'ABOUT US',
    ABOUTUSData: 'Founded in 2018 under the DigitalYard company, Scholigit is dedicated to revolutionizing educational management through innovative technology. Our all-in-one platform seamlessly integrates Learning Management System (LMS) and Enterprise Resource Planning (ERP) solutions, tailored specifically for schools and nurseries. With features including customized websites for student admissions and job postings, comprehensive HR and accounting modules, real-time bus notifications, digital wallets, and online stores, Scholigit simplifies administration and enhances the educational experience for administrators, teachers, students, and parents. Discover how Scholigit can streamline your institutions operations and focus on what truly matters – delivering quality education.',
    ReadMore:'Read More',
    "LMS": "Learning Management System (LMS):\nInteractive Online Classes: Facilitate engaging and interactive virtual classrooms.\nAssignment and Assessment Management: Streamline the creation, distribution, and grading of assignments and assessments.\nStudent Progress Tracking: Monitor and evaluate student performance and progress with detailed analytics and reports.\nResource Sharing: Enable teachers to share learning materials, multimedia content, and resources effortlessly.",
    "SMS": "Student Management System (SMS):\nAdmissions and Enrollment: Simplify the student admission and enrollment process with online applications and automated workflows.\nAttendance Tracking: Efficiently record and monitor student attendance with digital solutions.\nParent and Student Portals: Provide parents and students with access to grades, attendance records, and important announcements.\nCommunication Tools: Enhance communication between teachers, students, and parents through integrated messaging and notification systems.",
    "ERP": "Enterprise Resource Planning (ERP):\nHuman Resources (HR) Management: Manage employee records, payroll, performance evaluations, and more with a comprehensive HR module.\nAccounting and Finance: Oversee financial operations with tools for invoicing, budgeting, and expense tracking.\nInventory Management: Keep track of school inventory, including supplies, equipment, and other assets.\nBus Notifications: Ensure student safety with real-time bus notifications and route management.\nDigital Wallet and Online Store: Facilitate secure transactions for school fees, uniforms, books, and more through an integrated online store and digital wallet.",
  },


  MScreens: {
    LinkExpired: "Link Expired",
    plaeaseEnterMail:
      "Please Enter your email then click the button below to request another one.",
    email: "Email",
    mail_massage: "The input is not valid E-mail!",
    mail_req_massage: "Please input your E-mail!",
    ResendMail: "Resend Mail",
    oops: "Oops!! This URL Is Not Valid Anymore.",
    Ok: "OK",
    PasswordReset: "Passwords Reset successfully",
    ResetPassword: "RESET NEW PASSWORD",
    NewPassword: "New password",
    passwordMassage: "Please input your password!",
    passwordmin: "password must be min 8 characters",
    passwordmax: "password must be max 20 characters",
    passwordexpresion:
      "Password expresion that requires one lower case letter, one upper case letter, one digit",
    password_placeholder: "Enter your password",
    confirmPassword: "Confirm new password",
    confirm_massage: "Please confirm your password!",
    restPass_placeholder: "Confirm your password",
    cancle: "Cancle",
    resetSent: "Reset code was sent successfully",
    Reset: "Reset",
    CheckInpoxp1: "Please check your inbox or spam folder at",
    CheckInpoxp2: "for a link to reset your password .",
    backToSignin: "Back to sign in"
  },
  settings: {
    PasswordsChanged: "Passwords changed successfully",
    successfullyDeleted: " successfully Deleted",
    ChangePassword: "CHANGE PASSWORD",
    NewPassword: "New password",
    passwordMassage: "Please input your password!",
    passwordmin: "password must be min 8 characters",
    passwordmax: "password must be max 20 characters",
    passwordexpresion:
      "Password expresion that requires one lower case letter, one upper case letter, one digit",
    password_placeholder: "Enter your password",
    confirmPassword: "Confirm new password",
    confirm_massage: "Please confirm your password!",
    restPass_placeholder: "Confirm your password",
    logout: "logout",
    AccountDeactivated: "Account Deactivated!",
    YourAccountDeactivated:
      "Your account have be deactivated, please be informed that you’ll never be able to login again or restore your account",
    YesSave: "Yes, Save",
    NoSave: "No, Don’t Save",
    Cancel: "Cancel",
    Delete: "Delete",
    SaveChanges: "Save Changes",
    AreYouSoure: "Do you want to save this data ?",
    signIn: "Sign In",
    EnterPassFor: "Please enter Your Password for",
    password: "Password",
    passwordMassage: "Please input your password!",
    passwordmin: "password must be min 8 characters",
    passwordmax: "password must be max 20 characters",
    passwordexpresion:
      "Password expresion that requires one lower case letter, one upper case letter, one digit",
    password_placeholder: "Enter your password"
  },
  setting: {
    Settings: "Settings",
    Integratedappsmasterata: "Integrated apps master data",
    GoogleRECAPTCHA: "Google RECAPTCHA",
    GoogleAnalyticsTrackingCode: "Google Analytics Tracking Code",
    FacebookAppID: "Facebook App ID",
    FacebookAppSecret: "Facebook App Secret",
    LinkedInAppID: "LinkedIn App ID",
    EmailedLinksSettings: "Emailed Links Settings",
    Emailverification: "Email verification",
    Enable: "Enable",
    Emailverificationlinkexpirytimeminutes:
      "Email verification link expiry time (minutes)",
    Mins: "Mins",
    EmailNotifications: "Email Notifications",
    Resetpasslinkverificationlinkexpirytimeminutes:
      "Reset pass link verification link expiry time (minutes)",
    Resendmailinterval: "Resend mail interval",
    Tries: "Tries",
    Xplacholder: "xxxx-xxxx-xxxx"
  },
  Privileges: {
    AddRole: "Add Role",
    Newrole: "New Role",
    Personalinfo: "Personal info",
    Rolename: "Role name",
    ISACTIVE: "IS ACTIVE",
    Hidetranslations: "Hide translations",
    TranslateIntoAr: "Translate into “عربي“",
    TranslateIntoFr: "Translate into “française“",
    Enable: "Enable",
    Rolestatus: "Role status",
    Privileges: "Privileges",
    Privilege: "Privilege",
    AddPrivileges: "Add Privileges",
    SelectPrivileges: "Select Privileges",
    PleaseselectPrivilegestoAssign: "Please select Privileges to Assign",
    AssignedPrivileges: "Assigned Privileges",
    Admins: "Admins",
    AddAdmins: "Add Admins",
    SelectAdmins: "Select Admins",
    PleaseselectAdminstoAssign: "Please select Admins to Assign",
    deletedSuccessfully: "Selected roles deleted successfully",
    editedSuccessfully: "Selected role edited successfully",
    addedSuccessfully: "Role added successfully"
  },
  currency: {
    Currencyname: "Currency name",
    Addcurrency: "Add Currency",
    Newcurrency: "New currency",
    currencyinfo: "currency info"
  },
  users: {
    USERS: "USERS",
    Name: "Name",
    Age: "Age",
    Email: "Email",
    Address: "Address",
    Admins: "Admins",
    UpdateAdmins: "Update Admins",
    Adduser: "Add admin",
    Personalinfo: "Personal info",
    Rules: "Rules",
    Addrule: "Add rule",
    Selectrule: "Select rule",
    PleaseselectruletoAssign: "Please select role to Assign",
    Assignedrules: "Assigned rules",
    deletedSuccessfully: "Selected users deleted successfully",
    editedSuccessfully: "Selected user edited successfully",
    addedSuccessfully: "User added successfully"
  },
  grid: {
    New: "New ",
    goTo: "Go to"
  },
  buttons: {
    Close: "Close",
    SaveNew: "Save & New",
    Save: "Save",
    UpdateNew: "Update & New",
    Update: "Update",
    Duplicate: "Duplicate",
    Delete: "Delete",
    remove: "remove",
    Archive: "Archive",
    Edit: "Edit",
    confirm: "Are you sure you want to ",
    selectToEdit: "Please select one row to edit",
    selectToDelete: "Please select one row or more to delete",
    change: "Change",
    add: "Add",
    send: "Send",
    cancel: "Cancel",
    details: "Details",
    filter: "Filter",
    reset: "Reset"
  },
  inputs: {
    inputrequired: "input required",
    PleaseinputyourFirstname: "Please input your First name!",
    PleaseinputyourMiddlename: "Please input your Middle name!",
    PleaseinputyourLastName: "Please input your Last Name!",
    Pleaseinputyourphonenumber: "Please input your phone number!",
    mustbemin: "must be min 3 characters",
    cantaccept: "cannot accept space",
    specialcharacters: "cannot accept  special characters",
    acceptnumbers: "cannot accept numbers",
    Firstname: "First name",
    Middlename: "Middle name",
    LastName: "LastName",
    PhoneNumber: "Ex:01xxxxxxx",
    FirstnameH: "Ex: Ahmed",
    MiddlenameH: "Ex: Mohammed",
    LastNameH: "Ex: Kamel",
    PhoneNumberH: "Ex:01xxxxxxx",
    EmailH: "Ex: mail@Ex.com",
    phone: "phone",
    Email: "Email",
    TheinputisnotvalidEmail: "The input is not valid E-mail!",
    PleaseinputyourEmail: "Please input your E-mail!",
    EmailAlreadyExists: "Email Already Exists",
    Start: "Start",
    End: "End",
    inputrequired: "input required",
    PleaseinputyourFirstname: "Please input your First name!",
    PleaseinputyourMiddlename: "Please input your Middle name!",
    PleaseinputyourLastName: "Please input your Last Name!",
    Pleaseinputyourphonenumber: "Please input your phone number!",
    Firstname: "First name",
    Middlename: "Middle name",
    LastName: "Last Name",
    PhoneNumber: "Phone Number",
    phone: "phone",
    Email: "E-mail",
    TheinputisnotvalidEmail: "The input is not valid E-mail!",
    PleaseinputyourEmail: "Please input your E-mail!",
    EmailAlreadyExists: "Email Already Exists",
    Firstnamemustcharacters: " name must be min 3 characters",
    namecantacceptspace: "Name dosen't accept space",
    Namecanacceptspecialcharacters: "Name can't accept  special characters",
    Namecanacceptnumbers: "Name can't accept numbers"
  },
  tooltip: {
    tocontinuepleasesavefirst: "to continue please save first"
  },
  masterData: {
    basicInfo: "Basic Info",
    label: "Master Data",
    add: "Add",
    name: "Name",
    isActive: "Is active",
    active: "Active",
    inActive: "Inactive",
    translate: "Translation",
    hideTranslations: "Hide translations",
    namePlaceholder: "Enter name here",
    nameTranslationPlaceholder: "Enter name translation here",
    deletedSuccessfully: "Selected data deleted successfully",
    editedSuccessfully: "Selected data edited successfully",
    addedSuccessfully: "Data added successfully"
  },
  sideBar: {
    Admin: "Admins",
    Roles: "Roles",
    Schools: "Schools",
    MasterData: "Master Data",
    Setting: "Settings",
    Countries: "Countries",
    Positions: "Positions",
    PaymentMethods: "Payment Methods",
    Currency: "Currencies",
    Governorates: "Governorates",
    Sectors: "Sectors",
    Teams: "Teams",
    Governorates: "Governorates",
    Classes: "Classes",
    Teachers: "Teachers",
    Students: "Students",
    Installments: "Installments",
    News: "News",
    LostFound: "Lost & Found",
    Notifications: "Notifications"
  },
  filtration: {
    division: "Division",
    level: "Level",
    grade: "Grade",
    searchBy: "Search by name of",
    school: "school",
    template: "template",
    teacher: "teacher",
    student: "student",
    class: "class",
    item: "item",
    notification: "notification",
    news: "news"
  },
  uploader: {
    sizeExceeded: "Image size must be less than 2 MB",
    dimensionDoesntMatch: "Image dimension must be at least 200 * 200"
  },
  Classes: {
    Students: "Students",
    Toolkit: "Toolkit",
    Homework: "Homework",
    Attendance: "Attendance",
    SelectMultiple: "Select Multipl",
    selectSubject: "Select Subject",
    SetHomework: "Set Homework",
    UpdateHomework: "Update Homework",
    DeleteHomework: "Delete Homework",
    Random: "Random",
    Timer: "Timer",
    inviteParents: "invite Parents",
    HelpingOther: "Helping Other",
    OnTask: "On Task",
    participating: "participating",
    TeamWork: "Team Work",
    WorkingHard: "Working Hard",
    Givefeedbackto: "Give feedback to ",
    Positive: "Positive",
    NeedsWork: "Needs Work",
    Saveattendance: "Save attendance",
    Makeallpresent: "Make all present",
    MakeallAbsent: "Make all Absent",
    Classroom: "Classroom",
    ClassStory: "Class Story",
    Profile: "Profile",
    Messages: "Messages",
    selectAll: "Select All",
    to: "To:",
    NewMessage: "New Message",
    Cancel: "Cancel",
    next: "Next",
    send: "Send",
    writethemessagetitle: "write the message title",
    writeyourmessagehere: "write your message here…",
    Wet: "Wet",
    Dry: "Dry",
    BM: "BM"
  },
  timeline: {
    addPhoto: "Add Photo",
    addVideo: "Add Video",
    post: "Post",
    createTagline: "What’s happening in your classroom?",
    captionRequired: "Story caption is required",
    postCreatedSuccessfully: "Post created successfully",
    writeyourmessagehere: "write your message here…",
    WholeClass: "Whole Class",
    SetHomework: "Set Homework",
    Cancel: "Cancel"
  },
  Home: {
    LastUpdated: "Last Updated",
    Students: "Students",
    Mood: "Mood",
    Presence: "Presence",
    Division: "Division",
    ConnectParent: "Connect Parent"
  },
  ClassStory: {
    edit: "edit"
  },
  messages: {
    Messages: "Messages",
    Message: "Message",
    NewMessage: "New Message",
    AllParents: "All Parents",
    Send: "Send",
    Messageparent: "Message parent",
    writeyourmessagehere: "write your message here…"
  },
  timeline: {
    addPhoto: "Add Photo",
    addVideo: "Add Video",
    post: "Post",
    createTagline: "What’s happening in your classroom?",
    captionRequired: "Story caption is required",
    postCreatedSuccessfully: "Post created successfully"
  },
  puplicHome: {
    home: "Home",
    aboutus: "About Us",
    contactUs: "Contact Us",
    support: "support",
    faq: "FAQ",
    privacy:"privacy",
    ForParents: "For Parents",
    ForSchools: "For Schools",
    SchoolAdmin: "School Admin",
    RequestDemo: "Request Demo",
    OurSchool: "Our School",
    CompleteSolution: "Complete Solution",
    ReadMore: "Read More",
    Manageyour: " Manage your",
    Classwithschologit: "Class with scholigit",
    ParentsSolutions: "Parents Solutions",
    TeachersSolution: "Teachers Solution",
    Fullname: "Full name",
    email: "Email",
    phone: "Contact Number",
    writeMessage: "Please Write Your message",
    Allareconnected: "All are connected ",
    withScholigit: "with Scholigit",
    visitTeacherPortal: 'Teacher Application',
    sliderOne:
      "Scholigit has advantages for school/nursery, teachers and parents that helps each one of them to get more satisfaction and speeding the communication processes. ",
    Studentmanagement: "Student management",
    system: "system",
    sliderTwo:
      "A student management system with attendance, class schedule and reports.  ",
    Trackallfees: "Track all fees",
    sliderThree:
      "now all kids’ fees (installment – other fees) could be easily tracked and reported.",
    Notificationandmessaging: "Notification and messaging",
    sliderFour:
      "message and notify parents or teachers with our messaging and notification system.",
    OurSchoolText:
      "Scholigit is facilitating interactions between school/nursery, teachers and parents by connecting all parties in an unified platform.,",
    schoolAdminText:
      "Scholigit is a benefit for school/nursery administration to be more in touch with parent, teachers and classrooms through a student management system. ",
    parentText:
      "Guardians now can follow their kids step by step in their classrooms and track their progress with a direct teacher interaction. ",
    teacherText:
      "Teachers also will manage their classes and do their work easily without wasting time by informing parents with their kids’ statuses and reports through the teacher app or the web portal.",
    contactUsText:
      "We are happy to receive your comments and inquiries and we will contact you as soon as possible. ",
      Submit:"Submit",
      ortanizationType:"Organization Type",
      school:"school",
      Nursery:"Nursery",
      Other:"Other",
      WeBringTheUltimateFor :"We Bring The Ultimate Solution For ",
      Parents:"Parents",
      School :"School ",
      Communication:"Communication",
      requiredField:"This Field Is Required",
      scrollup:"scroll up",
      dressOne:"17 Al Ahram Street, Heliopolis, Cairo, Egypt. 3rd Floor, Entrance B",
      dressTwo:"Office # 2908 Al Manara Tower, Business Bay, Dubai, UAE.",
      scrolldown:"scroll down"
      
  }
};
